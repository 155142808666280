export const linkResolver = (doc) => {
  if (!doc.type) return '/'

  if (doc.type === 'case_study') {
    return `/case-study/${doc.uid}`
  }

  if (doc.type === 'blog_post') {
    return `/resources/${doc.uid}`
  }

  if (doc.type === 'product_page') {
    return `/product`
  }

  if (doc.type === 'customers_page') {
    return `/customers`
  }

  if (doc.type === 'solutions_page') {
    return `/solutions`
  }

  if (doc.type === 'partners_page') {
    return '/partners'
  }

  if (doc.type === 'company_page') {
    return '/company'
  }

  if (doc.type === 'Web') {
    return doc.url
  }

  if (doc.type === 'trust_center_page') {
    return `/trust-center`
  }

  return '/'
}
