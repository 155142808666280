import React, { useEffect } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { usePrismicPreview } from 'gatsby-source-prismic'
import { linkResolver } from '../components/_helpers/linkResolver'

// Note that the `location` prop is taken and provided to the `usePrismicPreview` hook.
const PreviewPage = ({ location }) => {
  console.log(location)
  
  // Let's use a static query to retrieve all known paths. We'll use it later
  // to navigate to the unpublishedPreview page if the document is not
  // published.
  const { allSitePage } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)
  const allPaths = allSitePage.nodes.map((node) => {
    if (node.path === '/') {
      return node.path
    } else {
      return node.path.replace(/\/$/, "")
    }
  })

  let { isPreview, previewData, path } = usePrismicPreview({
    // The repositoryName value from your `gatsby-config.js`.
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
  })

  // This useEffect runs when values from usePrismicPreview update. When
  // preview data is available, this will save the data globally and redirect to
  // the previewed document's page.
  useEffect(() => {
    // If this is not a preview, skip.
    //   null = Not yet determined if previewing.
    //   true = Preview is available.
    //   false = Preview is not available.
    if (isPreview === false) return

    // Save the preview data to somewhere globally accessible. This could be
    // something like a global Redux store or React context.
    //
    // We'll just put it on window.
    window.__PRISMIC_PREVIEW_DATA__ = previewData
    if (!path && previewData) {
      let pageObjectKey = Object.keys(previewData)[0]
      path = linkResolver(previewData[pageObjectKey])
    }

    // console.log(path, allPaths, previewData)

    if (path && previewData) {
      // Navigate to the document's page if page exists.
      console.log(allPaths, path);
      
      if (allPaths.includes(path)) {
        navigate(path)
      } else {
        // navigate('/unpublishedPreview')
      }
    }
  }, [isPreview, previewData, allPaths])

  // Tell the user if this is not a preview.
  if (isPreview === false) return <div className="full-centered">Not a preview!</div>

  return <div className="full-centered">Loading preview...</div>
}

export default PreviewPage